import { useContext } from 'react'
import useTranslation from 'next-translate/useTranslation'
import { AdjustmentsHorizontalIcon } from '@heroicons/react/24/solid'
import {
  JobsLocationsDocument,
  JobsLocationsQuery,
  JobsLocationsQueryVariables,
  LocaleEnum,
} from 'generated/apolloComponents'
import router from 'next/router'
import MultiSelect from '../atoms/Select/Multi'
import RawSelect, { SelectDataType } from '../atoms/Select/RawSelect'
import { ActionType, JobListContext } from '@/context/jobListContext'
import { roles, skills } from '@/data/jobData'
import { arrayToObject, technologies } from '@/helpers/utils'
import { initializeApollo } from '@/api/apollo'

interface iProps {
  jobsLocations: Array<object>
  slugState: any
  setIsVisible: (value: boolean) => void
  redirect?: boolean
}

const JobFiltersHeader = ({
  jobsLocations,
  slugState,
  setIsVisible,
  redirect,
}: iProps) => {
  const { t, lang } = useTranslation('common')
  const { state, dispatch } = useContext(JobListContext)
  const handleSelectChange = (value: SelectDataType | null, key: string) => {
    dispatch({
      type: ActionType.ChangeFilter,
      payload: { ...state.filters, [key]: value },
    })
  }
  const wrappedLocations = (data: Array<object>) => [
    { value: 'Remote', label: 'Remote' },
    { value: 'Abroad', label: t('common:abroad') },
    ...data,
  ]

  const apolloClient = initializeApollo({})
  const remoteLocationsData = async () => {
    const jobsLocationsData = await apolloClient.query<
      JobsLocationsQuery,
      JobsLocationsQueryVariables
    >({
      query: JobsLocationsDocument,
      variables: {
        amount: 100,
        language: LocaleEnum[lang as 'pl' | 'en'],
      },
    })
    return wrappedLocations(
      arrayToObject(jobsLocationsData.data.jobsLocations as string[])
    )
  }

  const remoteSkillsData = async () => {
    const technologiesData = technologies
    return arrayToObject(technologiesData as string[])
  }

  const filterClick = () => {
    redirect ? router.push('/companies/jobs?show=filters') : setIsVisible(true)
  }

  return (
    <div className='bg-action py-8 sticky top-[75px] z-10 hidden md:block'>
      <div className='container'>
        <form>
          <div className='flex justify-between items-center gap-8'>
            <div className='flex justify-between gap-6 grow'>
              <RawSelect
                state={state}
                onSelect={handleSelectChange}
                name='city'
                value={state?.filters?.city}
                data={wrappedLocations(jobsLocations)}
                title={t('common:localization')}
                remoteData={remoteLocationsData}
                className='my-3 grow lg:w-1/3 md:w-1/2'
              />
              <MultiSelect
                state={state}
                onSelect={handleSelectChange}
                name='role'
                value={slugState?.filters?.role}
                data={roles.map((item) => ({
                  ...item,
                  label: t(`common:role_${item.value}`),
                }))}
                title={t('common:position')}
                className='my-3 grow lg:w-1/3 md:w-1/2'
                addNew={false}
                isSearchable={false}
              />
              <RawSelect
                state={state}
                onSelect={handleSelectChange}
                name='skills'
                value={slugState?.filters?.skills}
                data={skills.general}
                title={t('common:technology')}
                className='my-3 grow lg:w-1/3 hidden lg:block'
                remoteData={remoteSkillsData}
              />
            </div>
            <button
              type='button'
              className='button-white-clear'
              onClick={filterClick}
            >
              <AdjustmentsHorizontalIcon className='w-6 mr-2' />
              {t('common:filter')}
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default JobFiltersHeader

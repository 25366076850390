import useTranslation from 'next-translate/useTranslation'

interface IProps {
  handleVisibleChange: any
  totalCount: number
  className?: string
}

const ShowOffersButton = ({
  handleVisibleChange,
  totalCount,
  className,
}: IProps) => {
  const { t, lang } = useTranslation('common')

  return (
    <div
      className={`${className} fixed bottom-0 h-32 right-0 pt-8 py-3 bg-white border-t border-gray-100 z-10 px-5 md:hidden w-full`}
    >
      <button
        type='button'
        onClick={handleVisibleChange}
        className='bg-black rounded-full py-4 block w-full font-normal text-xl  text-white  '
      >
        {t('common:show_offers')}{' '}
        <span className='text-gray-400'>({totalCount})</span>
      </button>
    </div>
  )
}

export default ShowOffersButton

import { useContext, useState } from 'react'
import useTranslation from 'next-translate/useTranslation'
import {
  AdjustmentsHorizontalIcon,
  HeartIcon,
  MagnifyingGlassIcon,
} from '@heroicons/react/24/solid'
import router from 'next/router'
import { Candidate } from 'generated/apolloComponents'
import { JobListContext } from '@/context/jobListContext'
import ShowOffersButton from '../atoms/ShowOffersButton'
import { SelectDataType } from '../atoms/Select/Multi'
import { sorting } from '@/data/sortingOptions'
import FixedInjectable from '../atoms/Select/FixedInjectable'
import SortButton from '../atoms/SortButton'
import { nonEmptyPropertiesCount } from '@/helpers/utils'

interface iProps {
  setIsVisible: (value: boolean) => void
  handleOrderChange: (value: SelectDataType | null, name: string) => void
  totalCount: number
  slugState?: any
  redirect?: boolean
  me?: Candidate
}

const JobFiltersFooterMobile = ({
  setIsVisible,
  handleOrderChange,
  totalCount,
  slugState,
  redirect,
  me,
}: iProps) => {
  const { t } = useTranslation('common')
  const { state } = useContext(JobListContext)
  const [isVisibleOrder, setIsVisibleOrder] = useState(false)
  const handleVisibleOrder = (e: any) => {
    e.preventDefault()
    e.stopPropagation()
    setIsVisibleOrder(!isVisibleOrder)
  }
  const onOrderChange = (value: SelectDataType | null) => {
    handleOrderChange(value, 'order')
    // setIsVisibleOrder(false);
  }

  const filterClick = () => {
    redirect ? router.push('/companies/jobs?show=filters') : setIsVisible(true)
  }
  return (
    <div className='bg-black py-5 sticky bottom-0 z-10 md:hidden'>
      <div className='container'>
        <form>
          <div className='flex justify-between items-center gap-8'>
            <button
              type='button'
              className='button-white-clear grow'
              onClick={filterClick}
            >
              {nonEmptyPropertiesCount(slugState.filters) > 0 ? (
                <>
                  <AdjustmentsHorizontalIcon className='w-6 mr-2' />
                  <span>
                    {t('common:filter')} (
                    {nonEmptyPropertiesCount(slugState.filters)})
                  </span>
                </>
              ) : (
                <>
                  <MagnifyingGlassIcon className='w-6 mr-2' />
                  <span>{t('common:search_jobs')}</span>
                </>
              )}
            </button>
            {me && me.likedJobs && me.likedJobs.length > 0 && (
              <a href='/companies/jobs/saved'>
                <button
                  type='button'
                  className='button-white-clear button-rounded'
                  aria-label={t('search:favourite')}
                >
                  <HeartIcon className='w-8 h-6 m-auto' />
                </button>
              </a>
            )}
            <div
              role='menuitem'
              tabIndex={0}
              onClick={() => setIsVisibleOrder(true)}
            >
              {!redirect && (
                <FixedInjectable
                  state={state}
                  type='single'
                  value={
                    state.order || {
                      label: t('common:default'),
                      value: { direction: 'default', field: undefined },
                    }
                  }
                  name='order'
                  onSelect={onOrderChange}
                  setIsParentVisible={setIsVisibleOrder}
                  data={sorting.map((item) => ({
                    ...item,
                    label: t(`common:${item.label}`),
                  }))}
                  title={t('common:sort')}
                  className='multiSelect--small block'
                  controlComponent={SortButton}
                />
              )}

              {isVisibleOrder && (
                <ShowOffersButton
                  handleVisibleChange={handleVisibleOrder}
                  totalCount={totalCount}
                  className='z-70'
                />
              )}
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default JobFiltersFooterMobile

import { BarsArrowUpIcon } from '@heroicons/react/24/solid'
import useTranslation from 'next-translate/useTranslation'

const SortButton = (props: any) => {
  const { t } = useTranslation('common')

  return (
    <button
      type='button'
      className='button-white-clear button-rounded'
      aria-label={t('sort')}
      // eslint-disable-next-line react/destructuring-assignment
      onClick={props.openMenu}
    >
      <BarsArrowUpIcon className='w-8 h-6 m-auto' />
    </button>
  )
}

export default SortButton
